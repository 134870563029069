@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--bg);
  color: var(--body-color, #dee2e6);
  text-align: left;
}
:root {
  --blue: #3498db;
  --indigo: #6610f2;
  --purple: #693a39;
  --pink: #aa2869;
  --red: #f3454a;
  --orange: #ffaa31;
  --yellow: #f8c988;
  --green: #7dc382;
  --teal: #37f0f0;
  --cyan: #7fd8e6;
  --white: #000;
  --gray: #c4c4ba;
  --gray-dark: #e9ecef;
  --shadow-light: rgba(151, 152, 155, 0.2);
  --shadow-dark: rgba(159, 162, 177, 0.8);
  --font-color: #fff;
  --body-color: #dee2e6;
  --charade: #2f2e42;
  --manhattan: #f5b498;
  --rhino: #274253;
  --terracotta: #dd765e;
  --linear-gradient: linear-gradient(
    to right top,
    #444359,
    #403f55,
    #3c3b50,
    #38374c,
    #343348,
    #313045,
    #2f2e42,
    #2c2b3f,
    #2a293d,
    #27263a,
    #252438,
    #232236
  );
  --shadow-only-light: rgba(0, 0, 0, 0);
  --navbar-light-color: rgba(255, 255, 255, 0.5);
  --navbar-light-hover-color: rgba(255, 255, 255, 0.7);
  --navbar-light-active-color: rgba(255, 255, 255, 0.9);
  --navbar-light-disabled-color: rgba(255, 255, 255, 0.3);
  --primary: #ffaa31;
  --secondary: #c4c4ba;
  --success: #7dc382;
  --info: #7fd8e6;
  --warning: #f8c988;
  --danger: #f3454a;
  --light: #343a40;
  --dark: #e7e1d7;
  --bg: #1e1c1f;
  --bg-color: #232024;
  --shadow-color: #1e1c1f;
  --bg-color-light: #5c4e4e55;
  --bg-color-nav: #413938;
  --bg-color-secondary: #24201f;
  --card-border-color: rgba(255, 255, 255, 0.125);
  --link-color: #66bce9;
  --link-hover-color: #166a96;
  --input-color: #dee2e6;
  --input-border-color: #6c757d;
  --border-color: #5c4e4e;
  --border-color-transparent: #5c4e4e80;
  --mask-fill-color: #1473e631;
  --transparent-font-color: #f5f3f380;
  --banner-background: #2f2e42;
  --banner-text: #f5b498;
  --highlight: rgba(248, 184, 139, 0.2);
  --breakpoint-xs: 0;
  --breakpoint-sm: 640px;
  --breakpoint-md: 820px;
  --breakpoint-lg: 1140px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: "Inter", sans-serif;
  --font-family-monospace: "Roboto Mono", SFMono-Regular, Menlo, Monaco,
    Consolas, "Liberation Mono", "Courier New", monospace;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
html {
  font-family: "Inter var", sans-serif;
}
html {
  font-family: Inter, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
